import { type ComboboxProps, Combobox } from '@iheartradio/web.companion';
import { forwardRef } from 'react';

export type CarouselComboboxProps = Omit<
  ComboboxProps,
  'autoComplete' | 'autocomplete' | 'css'
>;

export const CarouselCombobox = forwardRef<
  HTMLInputElement,
  CarouselComboboxProps
>(function CarouselCombobox(props: CarouselComboboxProps, ref) {
  return (
    <Combobox
      css={{
        '&, & *': {
          font: 'inherit',
        },
        '& > input': {
          dark: {
            color: '$blue-400',
          },
          light: {
            color: '$blue-600',
          },
        },
      }}
      {...props}
      ref={ref}
    />
  );
});
