import {
  Combobox,
  media,
  Text,
  useMediaQuery,
} from '@iheartradio/web.companion';
import { useSearchParams } from '@remix-run/react';
import { useState } from 'react';

import { getMarketLabel } from '~app/api/markets';
import { CarouselCombobox } from '~app/components/carousel-combobox/carousel-combobox';
import { useSetMarketId } from '~app/hooks/use-set-market-id';
import { useRootLoaderData } from '~app/root';

export const MarketsDropdown = ({ sectionTitle }: { sectionTitle: string }) => {
  const {
    market: initialMarket,
    marketNamesById,
    orderedMarkets,
  } = useRootLoaderData();
  const [marketId, setMarketId] = useSetMarketId(initialMarket.marketId);

  const [_searchParams, setSearchParams] = useSearchParams();
  const [isFocused, setIsFocused] = useState(false);
  const isMobileBreakpoint = useMediaQuery(media.largeMax);

  const items = orderedMarkets.map(market => ({
    id: market.marketId,
    name: getMarketLabel(market),
  }));

  const marketNameSize = getMarketLabel(marketNamesById[marketId]).length;

  return (
    <Text
      alignItems="baseline"
      as="div"
      display="flex"
      flexWrap="wrap"
      gap="$8"
      kind={{ '@initial': 'h4', '@large': 'h3' }}
      width="100%"
    >
      <Text as="h3" kind={{ '@initial': 'h4', '@large': 'h3' }}>
        {sectionTitle}
      </Text>
      <CarouselCombobox
        aria-label="Market Search"
        defaultInputValue={getMarketLabel(marketNamesById[marketId])}
        // Specify items for the combobox, filtering is handled for us under the hood
        defaultItems={items}
        // we want to adjust it based on the length of the market name
        inputSize={isMobileBreakpoint ? marketNameSize + 2 : marketNameSize}
        // Track `isFocused` to know whether the <Popover /> (aka Dropdown) should be open
        isFocused={isFocused}
        menuTrigger="focus"
        name="live-stations"
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onSelectionChange={value => {
          if (!value) {
            return;
          }
          setMarketId(Number(value));
          setSearchParams(
            params => {
              params.set('market', value.toString());
              return params;
            },
            { replace: true, preventScrollReset: true },
          );
          if (!isMobileBreakpoint) {
            setIsFocused(false);
          }
        }}
        selectedKey={marketId}
        shouldFocusWrap
      >
        {item => <Combobox.ListBoxItem>{item.name}</Combobox.ListBoxItem>}
      </CarouselCombobox>
    </Text>
  );
};
